import Alpine from 'alpinejs';
export default function () {
   Alpine.store('state', {
      _selecting (active, location) {
         this.selecting = active;
         this.location = location;
         this.dispatch('selecting');
      },
      _sidebar (active, view) {
         this.sidebar = active;
         this.sidebarview = view;
         this.dispatch('sidebar');
      },
      _uploading (active) {
         this.uploading = active;
         this.dispatch('uploading');
      },
      sidebarview: null,
      sidebar: false,
      selecting: false,
      selectable: false,
      uploading: false,
      location: null,
      getState () {
         let showPager = this.sidebarview === 'all-media' && this.sidebar;
         let disablePager =
            (this.sidebarview === 'all-tags' && this.sidebar) ||
            this.uploading ||
            (this.selecting && !this.sidebar);
         let readonly =
            (this.sidebar && this.sidebarview === 'all-tags') ||
            (this.selecting && !this.sidebar);
         let selecting = this.selecting;
         let editingTags = this.sidebarview === 'all-tags';
         let uploading = this.uploading;
         let editingTagsDisabled = this.selecting || this.uploading;
         let tagBarEnabled =
            !this.uploading &&
            ((this.sidebar && this.sidebarview === 'all-media') ||
               (!this.sidebar && !this.selecting));
         let selectable = !(this.sidebar || this.uploading);
         let uploadable = !(this.sidebar || this.selecting || this.uploading);
         let filterable = !(this.selecting || this.uploading);
         let showTrash = !(this.selecting || this.sidebar || this.uploading);
         let showRemove = !(this.selecting || this.sidebar || this.uploading);
         return {
            showPager,
            disablePager,
            selecting,
            readonly,
            editingTagsDisabled,
            editingTags,
            selectable,
            uploadable,
            uploading,
            filterable,
            tagBarEnabled,
            showTrash,
            showRemove
         };
      },
      dispatch (fn) {
         let state = this.getState();
         fn = fn ? { fn } : {};
         state = { ...state, ...fn };
         let event = new CustomEvent('shared-state', {
            detail: {
               state
            }
         });
         dispatchEvent(event);
      }
   });
}
