import Alpine from 'alpinejs';
import { UAParser } from 'ua-parser-js';
export default function () {
   window.UAParser = UAParser;
   Alpine.store('mobile', {
      iphone () {
         return UAParser(navigator.userAgent).device?.model === 'iPhone';
      },
      android () {
         return UAParser(navigator.userAgent).device?.os === 'Android';
      },
      phone () {
         return UAParser(navigator.userAgent).device?.type === 'mobile';
      },
      desktop () {
         return UAParser(navigator.userAgent).device?.type !== 'mobile';
      },
      clickEvent ($input) {
         const fakeInput = document.createElement($input.tagName);
         if ($input.tagName === 'INPUT') {
            fakeInput.setAttribute('type', 'text');
         }
         fakeInput.style.position = 'absolute';
         fakeInput.style.opacity = 0;
         fakeInput.style.height = 0;
         fakeInput.style.fontSize = '16px'; // disable auto zoom
         // you may need to append to another element depending on the browser's auto
         // zoom/scroll behavior
         document.body.prepend(fakeInput);
         // focus so that subsequent async focus will work

         fakeInput.focus();

         setTimeout(() => {
            $input.focus();
            $input.selectionStart = $input.value.length + 1;
            $input.selectionEnd = $input.value.length + 2;

            // cleanup
            fakeInput.remove();
         }, 100);
      }
   });
}
