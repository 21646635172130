const abandon = function (Alpine) {
   Alpine.magic('abandon', () => key => {
      setTimeout(() => localStorage.removeItem(key), 300);
   });
};
const stripQuotes = function (Alpine) {
   Alpine.magic('stripQuotes', () => str => str.replace(/"/g, ''));
};

export { abandon, stripQuotes };
